<template>
  <div ref="reference" class="game-card-animation">
    <Teleport v-if="isAnimated" to="body">
      <div class="teleport-container">
        <div :style="floatingStyles" class="floating-container">
          <img
            ref="floating"
            class="game-card-image-animation"
            :src="image"
            alt=""
            width="140"
            loading="lazy"
          />
        </div>
        <div v-if="isAnimated" class="loading-container">
          <div class="animated-loader" :style="loaderStartPosition">
            <StIcon name="loading" size="32" data-t="loading-spinner" />
            <span class="loading-message">{{
              t('casino.loadingDotsFull')
            }}</span>
          </div>
        </div>
        <MGameFooter
          :game-id="gameId"
          class="footer"
          :class="{ animated: isAnimated }"
        />
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { offset, flip, useFloating } from '@st/ui/libs'
// фейковый футер игры для бесшовного перехода к странице игры
import MGameFooter from '../MGamePage/parts/MGameFooter.vue'

const {
  image,
  isAnimated = false,
  gameId,
} = defineProps<{
  image: string
  isAnimated: boolean
  gameId: number
}>()

const { t } = useI18n()

const reference = ref<HTMLDivElement>()
const floating = ref<HTMLDivElement>()

/**
 * при телепорте в body помещаем картинку
 * для анимации над основной картинкой карточки игры через floatingStyles
 * чтобы получить эффект анимации увеличения только картинки
 */
const { floatingStyles } = useFloating(reference, floating, {
  middleware: [offset(({ rects }) => -rects.reference.height), flip()],
  strategy: 'absolute',
})

/**
 * позиционирование лоадера относительно картинки
 * чтобы достичь эффекта перемещения лоадера вместе с картинкой
 * из нужного места
 */
const loaderStartPosition = computed(() => ({
  top: `${reference.value?.getBoundingClientRect().top ?? 0}px`,
  left: `${reference.value?.getBoundingClientRect().right ?? 0}px`,
}))
</script>

<style scoped>
.teleport-container {
  position: fixed;
  z-index: 20;
  inset: 0;
}

.floating-container {
  z-index: 20;
}

.loading-container {
  position: absolute;
  z-index: 20;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  + .footer {
    :deep(.game-footer) {
      transform: translateY(60px);
    }

    &.animated {
      :deep(.game-footer) {
        position: absolute;
        z-index: 20;
        animation: slide 0.35s 0.35s ease-in-out forwards;
      }
    }
  }
}

.animated-loader {
  position: absolute;
  transform: scale(0.5);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  opacity: 0;

  animation: show 0.35s 0.15s ease-in-out forwards;
}

.loading-message {
  font: var(--mobile-text-content-regular);
  color: var(--text-secondary);
  white-space: nowrap;
}

.game-card-animation {
  pointer-events: none;
  position: absolute;
  z-index: 20;
  inset: 0;
}

.game-card-image-animation {
  position: absolute;
  z-index: 20;
  animation: zoom 0.75s ease-in-out forwards;
}

@keyframes zoom {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }

  80% {
    transform: scale(13);
    filter: brightness(0%);
  }

  100% {
    transform: scale(15);
    filter: brightness(0%);
  }
}

@keyframes show {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    transform: translateY(60px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
